import {
  forest75,
  greenDesat185,
  midnight35,
  pumpkin135,
  solidWhite200,
} from '@spotify-internal/encore-web';

/**
 * Not Found - The requested resource could not be found but may be available in
 * the future. Subsequent requests by the client are permissible.
 */
export const CODE_404 = 404;

/**
 * Internal Server Error - A generic error message, given when an unexpected
 * condition was encountered and no more specific message is suitable.
 */
export const CODE_500 = 500;

export const ERROR_404 = {
  CODE: CODE_404,
  DESCRIPTION: '404.description', // i18n key
  MESSAGE: '404.message', // i18n key
  COLOR: solidWhite200,
  MESSAGE_COLOR: greenDesat185,
  BACKGROUND_COLOR: forest75,
};
export const ERROR_500 = {
  CODE: CODE_500,
  DESCRIPTION:
    'Looks like something went wrong. We’re currently experiencing an internal server problem.',
  MESSAGE: 'Hmm. This page doesn’t seem to be working.',
  COLOR: solidWhite200,
  MESSAGE_COLOR: pumpkin135,
  BACKGROUND_COLOR: midnight35,
};
export const ERRORS = [ERROR_404, ERROR_500];
