import { gql } from '@apollo/client';

import { IMG_QUALITY } from 'constants/image-attributes';

// Composable Module GraphQL fragments (eg. RTE, Image, Quote)
// TODO: Field textColor to be deprecated on HERO.
export const MODULE_FRAGMENTS = {
  MARKDOWN_TEXT: gql`
    fragment MarkdownText on ModuleMarkdownText {
      sys {
        id
      }
      markdownBody: body
      onSplitView
      displayOnMobile
    }
  `,
  SPOTIFY_PLAYER: gql`
    fragment SpotifyPlayer on ModuleSpotifyPlayer {
      sys {
        id
      }
      uri
    }
  `,
  CATALOG: gql`
    fragment Catalog on ModuleCatalog {
      sys {
        id
      }
      title
      catalogEntriesCollection(limit: 12) {
        items {
          sys {
            id
          }
          title
          description
          eyebrow
          cta {
            ...cta
          }
          image {
            ...image
          }
        }
      }
    }
  `,
  RTE: gql`
    fragment RTE on ModuleRichText {
      sys {
        id
      }
      body {
        json
      }
    }
  `,
  IMAGE: gql`
    fragment Image on ModuleImage {
      sys {
        id
      }
      name
      image {
        ...image
      }
      caption
      aspectRatio
      displayOnMobile
    }
  `,
  ACCORDION: gql`
    fragment Accordion on ModuleAccordion {
      listsCollection {
        items {
          title
          description
          cta {
            ...cta
          }
        }
      }
      displayOnMobile
      isDark
      themeSelector
    }
  `,
  AUDIO: gql`
    fragment Audio on ModuleAudio {
      sys {
        id
      }
      title
      audio {
        url
        contentType
        fileName
      }
      caption
      titleColor
    }
  `,
  VIDEO: gql`
    fragment Video on ModuleVideo {
      sys {
        id
      }
      type
      videoId
      name
      caption
      verticalVideo {
        url
        contentType
      }
      heroVideo {
        url
        contentType
      }
      autoplay
    }
  `,
  QUOTE: gql`
    fragment Quote on ModuleQuote {
      sys {
        id
      }
      quote
      speaker
      companyOrRole
      backgroundColor
      quoteColor
      attributionTextColor
      displaySplitView
      displayOnMobile
      centered
      isArticleMargin
      cta {
        ...cta
      }
    }
  `,
  STATS: gql`
    fragment Stats on ModuleStats {
      sys {
        id
      }
      title
      backgroundColor
      themeValue: theme
      displayOnMobile
      statsCollection {
        items {
          ... on Stat {
            name
            color
            stat
            title
            descriptionText
          }
        }
      }
    }
  `,
  FEATURE_CARD_CONTAINER: gql`
    fragment FeatureCardContainer on ModuleFeatureCardContainer {
      sys {
        id
      }
      themeSelector
      featureCardsCollection(limit: 6) {
        items {
          ... on ModuleFeatureCard {
            ...FeatureCardWithForm
          }
        }
      }
    }
  `,
  FEATURE_CARD: gql`
    fragment FeatureCard on ModuleFeatureCard {
      sys {
        id
      }
      image {
        ...image
      }
      eyebrow
      title
      titleColor
      theme
      themeSelector
      description
      backgroundColor
      imagePosition
      imagePositionMobile
      displayOnMobile
      ctasCollection(limit: 2) {
        items {
          ...cta
        }
      }
      columnList
      id
    }
  `,
  FEATURE_CARD_WITH_FORM: gql`
    fragment FeatureCardWithForm on ModuleFeatureCard {
      sys {
        id
      }
      image {
        ...image
      }
      eyebrow
      title
      titleColor
      theme
      themeSelector
      description
      backgroundColor
      imagePosition
      imagePositionMobile
      displayOnMobile
      ctasCollection(limit: 2) {
        items {
          ...cta
        }
      }
      columnList
      popUpForm {
        ...InlineForm
      }
      id
    }
  `,
  CHROME_FEATURE_CARD: gql`
    fragment ChromeFeatureCard on ModuleChromeFeatureCard {
      sys {
        id
      }
      image {
        ...image
      }
      eyebrow
      title
      titleColor
      theme
      description
      backgroundColor
      imagePosition
      ctasCollection(limit: 2) {
        items {
          ...cta
        }
      }
      imageType
      columnList
    }
  `,
  VIDEO_FEATURE_CARD: gql`
    fragment VideoFeatureCard on ModuleVideoFeatureCard {
      sys {
        id
      }
      moduleVideo {
        videoId
        name
        type
        verticalVideo {
          url
        }
        autoplay
      }
      eyebrow
      title
      titleColor
      theme
      description
      backgroundColor
      videoPosition
      displayOnMobile
      isArticleMargin
      ctasCollection(limit: 2) {
        items {
          ...cta
        }
      }
      columnList
    }
  `,
  TOPIC_CAROUSEL: gql`
    fragment TopicCarousel on ModuleTopicCarousel {
      sys {
        id
      }
      eyebrow
      displayOnMobile
      topicEntriesCollection(limit: 5) {
        items {
          sys {
            id
          }
          image {
            ...image
          }
          title
          description
          cta {
            ...cta
          }
          backgroundColor
          theme
        }
      }
    }
  `,
  TOPIC: gql`
    fragment Topic on ModuleTopic {
      sys {
        id
      }
      title
      headlineColor
      backgroundColor
      themeValue: theme
      audioWithTranscript {
        eyebrow
        title
        image {
          ...image
        }
        imageTitle
        imageSubtitle
        description
        audio {
          url
          contentType
        }
        transcript {
          url
        }
        cta {
          ...cta
        }
      }
    }
  `,
  INLINE_FORM: gql`
    fragment InlineForm on ModuleInlineForm {
      sys {
        id
      }
      name
      title
      action
      ctaText
      disclaimer
      failureMessage
      formBackgroundColor
      fullWidthBackgroundColor
      formTextColor
      placeholderTextColor
      displayOnMobile
      successMessage {
        body {
          json
        }
      }
      fieldsCollection {
        items {
          ... on InlineFormFieldInput {
            fieldName
            type
            required
            label
          }
        }
      }
      isDark
      isPopUp
      popUpButtonText
    }
  `,
  JUMP_LINK_GROUP: gql`
    fragment JumpLinkGroup on ModuleJumpLinkGroup {
      sys {
        id
      }
      name
      linksCollection {
        items {
          sys {
            id
          }
          title
          url
        }
      }
    }
  `,
  DYNAMIC_TEXT_INTRO: gql`
    fragment DynamicTextIntro on ModuleDynamicTextIntro {
      heading
      subHeading
      jumpText
      displayOnMobile
    }
  `,
  INLINE_CARD: gql`
    fragment InlineCard on ModuleInlineCard {
      sys {
        id
      }
      image {
        ...image
      }
      fullWidthImage
      eyebrow
      title
      subtitle
      description
      displayOnMobile
      textColor
      backgroundColor
      isArticleMargin
      themeSelector
      ctasCollection(limit: 9) {
        items {
          ...cta
        }
      }
    }
  `,
  AD_EXPERIENCES: gql`
    fragment AdExperiences on ModuleAdExperiences {
      sys {
        id
      }
      introTitle
      introImage {
        ...image
      }
      eyebrow
      title1
      description1
      title2
      description2
      title3
      description3
      ctaHelpText
      cta {
        ...cta
      }
    }
  `,
  TAGGED_CONTENT_GROUP: gql`
    fragment TaggedContentGroup on ModuleTaggedContentGroup {
      sys {
        id
      }
      name
      id
      title
      noResultsText
      noResultsSubText
      categoriesCollection(limit: 4) {
        items {
          ... on ModuleTaggedContentCategory {
            name
            id
            allOptionEnabled
            subcategoriesCollection(limit: 20) {
              items {
                ... on ModuleTaggedContentSubCategory {
                  name
                  id
                }
              }
            }
          }
        }
      }
    }
  `,
  CONTENT_CARD_CURATED_LIST: gql`
    fragment ContentCardCuratedList on ModuleContentCardCuratedList {
      title
      name
      cardsCollection(limit: 6) {
        items {
          ... on ContentCard {
            ...contentCard
          }
        }
      }
    }
  `,
  TABBED_CURATED_LIST: gql`
    fragment TabbedCuratedList on ModuleTabbedCuratedList {
      name
      listsCollection(limit: 6) {
        items {
          ...ContentCardCuratedList
        }
      }
    }
  `,
  TREND_GRID_WITH_TRENDS: gql`
    fragment TrendGridWithTrends on ModuleTrendGrid {
      name
      title
      description
      cta {
        ...cta
      }
      scrollVertical
      trendCardsCollection(limit: 12) {
        items {
          ... on ContentTile {
            ...contentTileWithTrends
          }
        }
      }
    }
  `,
  TREND_GRID: gql`
    fragment TrendGrid on ModuleTrendGrid {
      name
      title
      description
      cta {
        ...cta
      }
      scrollVertical
      trendCardsCollection(limit: 12) {
        items {
          ... on ContentTile {
            ...contentTile
          }
        }
      }
    }
  `,
  SLIDER_TAB: gql`
    fragment SliderTab on ModuleSliderTab {
      name
      eyebrow
      title
      description
      cta {
        ...cta
      }
      imagePosition
      cardsCollection(limit: 3) {
        items {
          ... on ModuleFeatureCard {
            ...FeatureCard
          }
          ... on ModuleGallery {
            ...Gallery
          }
        }
      }
    }
  `,
  STAT_SLIDESHOW: gql`
    fragment StatSlideshow on ModuleStatSlideshow {
      statCardsCollection(limit: 5) {
        items {
          ... on StatCard {
            ...statCard
          }
        }
      }
    }
  `,
  LIST: gql`
    fragment List on ModuleList {
      listItemsCollection(limit: 8) {
        items {
          ... on ListItem {
            ...listItem
          }
        }
      }
      isSplitView
    }
  `,
  SPLIT_VIEW_DETAIL: gql`
    fragment SplitViewDetail on ModuleSplitView {
      leftContentCollection(limit: 1) {
        items {
          ... on ModuleTileCarousel {
            ...TileCarouselContentTile
          }
          ... on ModuleImage {
            image {
              ...image
            }
            aspectRatio
          }
        }
      }
      rightContentCollection(limit: 1) {
        items {
          ... on ModuleImage {
            image {
              ...image
            }
            aspectRatio
          }
          ... on ModuleList {
            ...List
          }
        }
      }
    }
  `,
  SPLIT_VIEW_ONLY_CONTENT: gql`
    fragment SplitViewOnlyContent on ModuleSplitView {
      name
      title
      description
      eyebrow
      backgroundColor
      fullWidth
      cta {
        ...cta
      }
      leftContentCollection(limit: 1) {
        items {
          ... on ModuleTileCarousel {
            ...TileCarouselContentTile
          }
          ... on ModuleAccordion {
            ...Accordion
          }
          ... on ModuleImage {
            image {
              ...image
            }
            aspectRatio
          }
          ... on ModuleQuote {
            ...Quote
          }
          ... on ModuleMarkdownText {
            ...MarkdownText
          }
        }
      }
      rightContentCollection(limit: 1) {
        items {
          ... on ModuleImage {
            image {
              ...image
            }
            aspectRatio
          }
          ... on ModuleQuote {
            ...Quote
          }
          ... on ModuleMarkdownText {
            ...MarkdownText
          }
          ... on ModuleList {
            ...List
          }
        }
      }
    }
  `,
  SPLIT_VIEW: gql`
    fragment SplitView on ModuleSplitView {
      name
      title
      description
      eyebrow
      backgroundColor
      fullWidth
      cta {
        ...cta
      }
      leftContentCollection(limit: 1) {
        items {
          ... on ModuleTileCarousel {
            ...TileCarousel
          }
          ... on ModuleAccordion {
            ...Accordion
          }
          ... on ModuleImage {
            image {
              ...image
            }
            aspectRatio
          }
          ... on ModuleQuote {
            ...Quote
          }
          ... on ModuleMarkdownText {
            ...MarkdownText
          }
        }
      }
      rightContentCollection(limit: 1) {
        items {
          ... on ModuleImage {
            image {
              ...image
            }
            aspectRatio
          }
          ... on ModuleQuote {
            ...Quote
          }
          ... on ModuleMarkdownText {
            ...MarkdownText
          }
          ... on ModuleList {
            ...List
          }
        }
      }
    }
  `,
  TILE_CAROUSEL_ONLY_CONTENT_TILE: gql`
    fragment TileCarouselContentTile on ModuleTileCarousel {
      name
      title
      titleColor
      description
      cta {
        ...cta
      }
      scrollHorizontal
      scrollHorizontalMobile
      fullWidth
      isArticleMargin
      contentTilesCollection(limit: 6) {
        items {
          ... on ContentTile {
            ...contentTile
          }
        }
      }
    }
  `,
  TILE_CAROUSEL: gql`
    fragment TileCarousel on ModuleTileCarousel {
      name
      title
      titleColor
      description
      cta {
        ...cta
      }
      scrollHorizontal
      scrollHorizontalMobile
      fullWidth
      isArticleMargin
      contentTilesCollection(limit: 6) {
        items {
          ... on ContentTile {
            ...contentTile
          }
          ... on StatCard {
            ...statCard
          }
          ... on CtaCard {
            ...ctaCard
          }
        }
      }
    }
  `,
  FOOTNOTES: gql`
    fragment Footnotes on ModuleFootnotes {
      name
      footnotes
      dropdown
    }
  `,
  GALLERY: gql`
    fragment Gallery on ModuleGallery {
      title
      columns
      rows
      itemsCollection(limit: 20) {
        items {
          ... on CtaCard {
            ...ctaCard
          }
          ... on ModuleImage {
            image {
              ...image
            }
            aspectRatio
          }
        }
      }
    }
  `,
  SECTION_DIVIDER: gql`
    fragment SectionDivider on ModuleSectionDivider {
      name
    }
  `,
};

// Component GraphQL fragments (eg. Hero)
export const COMPONENT_FRAGMENTS = {
  HERO: gql`
    fragment HeroFields on Hero {
      sys {
        id
      }
      eyebrow
      title
      description
      ctasCollection(limit: 2) {
        items {
          ...cta
        }
      }
      foregroundImage {
        ...image
      }
      video {
        videoId
        name
        type
        heroVideo {
          url
          contentType
        }
      }
      backgroundColor
      titleColor
      textAlign
      mediaAlign
    }
  `,
  HERO_WITH_FORM: gql`
    fragment HeroFieldsWithForm on Hero {
      sys {
        id
      }
      eyebrow
      title
      description
      ctasCollection(limit: 2) {
        items {
          ...cta
        }
      }
      foregroundImage {
        ...image
      }
      video {
        videoId
        name
        type
        heroVideo {
          url
          contentType
        }
      }
      backgroundColor
      titleColor
      textAlign
      mediaAlign
      popUpForm {
        ...InlineForm
      }
    }
  `,
  HERO_DYNAMIC: gql`
    fragment HeroDynamic on HeroDynamic {
      titleIntro
      titleOutros
      description
      backgroundColorBurst
      ctasCollection(limit: 2) {
        items {
          ...cta
        }
      }
      mediaAsset {
        url
        webpUrl: url(transform: { quality: ${IMG_QUALITY}, format: WEBP })
        optimizedUrl: url(transform: { quality: ${IMG_QUALITY} })
        contentType
      }
    }
  `,
  AUTHOR: gql`
    fragment AuthorFields on Author {
      authorName
      authorRole
    }
  `,
  META: gql`
    fragment MetaFields on Meta {
      name
      metaTitle
      metaDescription
      keywords
      canonicalUrl
      image {
        ...image
      }
      hreflangsCollection {
        items {
          url
          localeId
        }
      }
    }
  `,
  CTA: gql`
    fragment cta on Cta {
      title
      url
      type
      overrideFunctionality
    }
  `,
  IMAGE: gql`
    fragment image on Asset {
      url
      webpUrl: url(transform: { quality: ${IMG_QUALITY}, format: WEBP })
      optimizedUrl: url(transform: { quality: ${IMG_QUALITY} })
      description
    }
  `,
  CONTENT_CARD: gql`
    fragment contentCard on ContentCard {
      title
      image {
        ...image
      }
      tags
      ctaText
      paragraph
    }
  `,
  STAT_CARD: gql`
    fragment statCard on StatCard {
      title
      subtitle
      captionTitle
      caption
      textColor
      backgroundColor
      image {
        ...image
      }
      themeSelector
    }
  `,
  LIST_ITEM: gql`
    fragment listItem on ListItem {
      eyebrow
      title
      url
      image {
        ...image
      }
      showBackgroundColor
      textColor
    }
  `,

  CTA_BANNER: gql`
    fragment ctaBanner on CtaBanner {
      title
      description
      backgroundColor
      textColor
      isArticleMargin
      ctasCollection(limit: 3) {
        items {
          ...cta
        }
      }
    }
  `,

  TREND_CARD: gql`
    fragment trendCard on TrendCard {
      title
      description
      accentColor
      image {
        ...image
      }
      insightsTitle
      trendInsightCollection(limit: 6) {
        items {
          ... on StatCard {
            ...statCard
          }
        }
      }
      insightsQuote {
        ...Quote
      }
      insightsImage {
        ...image
      }
      insightsImageCaption
      ctasCollection(limit: 3) {
        items {
          ... on CtaBanner {
            ...ctaBanner
          }
        }
      }
      footnotes {
        ...Footnotes
      }
    }
  `,
  CONTENT_TILE_NO_TRENDS: gql`
    fragment contentTile on ContentTile {
      title
      description
      eyebrow
      image {
        ...image
      }
      backgroundColor
      textColor
      cta {
        ...cta
      }
      themeSelector
    }
  `,
  CONTENT_TILE_WITH_TRENDS: gql`
    fragment contentTileWithTrends on ContentTile {
      title
      description
      eyebrow
      image {
        ...image
      }
      backgroundColor
      textColor
      cta {
        ...cta
      }
      themeSelector
      trendInfo {
        ...trendCard
      }
    }
  `,
  CTA_CARD: gql`
    fragment ctaCard on CtaCard {
      title
      description
      image {
        ...image
      }
      ctasCollection(limit: 4) {
        items {
          ...cta
        }
      }
      themeSelector
    }
  `,
};

export const DYNAMIC_FRAGMENTS = {
  CURATED_LIST_PAGE_DETAIL: locale => gql`
    fragment pageDetail on PageDetail${locale} {
      slug
      eyebrowOverride
      navigationTag {
        title
        slug
      }
      meta {
        image {
          ...image
        }
        metaTitle
        metaDescription
      }
      hero {
        title
      }
    }
  `,
  CURATED_LIST_PAGE_GLOBAL: gql`
    fragment pageDetailGlobal on PageDetailGlobal {
      slug
      eyebrowOverride
      navigationTag {
        title
        slug
      }
      meta {
        image {
          ...image
        }
        metaTitle
        metaDescription
      }
      hero {
        title
      }
    }
  `,
  CURATED_LIST_PAGE_LANDING: locale => gql`
    fragment pageLanding on PageLanding${locale} {
      navigationTag {
        title
        slug
      }
      meta {
        image {
          ...image
        }
        metaTitle
        metaDescription
      }
      hero {
        title
      }
    }
  `,
  CURATED_LIST_PAGE_TRENDS: gql`
    fragment pageDynamicLanding on PageDynamicLanding {
      slug
      navigationTag {
        title
        slug
      }
      meta {
        image {
          ...image
        }
        metaTitle
        metaDescription
      }
      hero {
        title
      }
    }
  `,
};
